import { ref } from '@vue/composition-api'

import { useAsyncState } from '@vueuse/core'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import listModule from '@/navigation/vertical'

import store from '@/store'

export default function useAdminMembersList() {
  const toast = useToast()

  const modulesData = ref([])
  const statusOptions = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
  ]
  const defaultCrud = {
    create: false,
    read: false,
    update: false,
    delete: false,
  }

  const allModulesName = listModule.flatMap(module => {
    if (module.children) {
      return module.children.flatMap(item => item.resource)
    }

    if (module.title) {
      return module.resource
    }
    return []
  })

  const defaultAbility = listModule
    .flatMap(module => {
      if (module.children) {
        return module.children.flatMap(item => ({
          module: item.resource,
          ...defaultCrud,
        }))
      }

      if (module.title) {
        return { module: module.resource, ...defaultCrud }
      }
      return []
    })
    .concat({
      module: 'Auth',
      ...defaultCrud,
      read: true,
    })

  // const uniqWith = (arr, fn) => arr.filter((el, i) => arr.findIndex(step => fn(el, step)) === i)

  const hideAuth = (item, type) => {
    if (!item || type !== 'row') return
    // eslint-disable-next-line consistent-return
    if (item.module === 'Auth') return { hidden: true }
  }

  // const isThereTrue = module => {
  //   if (module.module === 'Auth') return false
  //   if (module.create) return true
  //   if (module.read) return true
  //   if (module.update) return true
  //   if (module.delete) return true
  //   return false
  // }

  // const isModuleHasSomeTruthyValue = computed(() =>
  //   modulesData.value.some(field => isThereTrue(field))
  // )

  const { isLoading, execute: updateAdmin } = useAsyncState(
    async args => {
      // eslint-disable-next-line no-shadow
      const { ability, ...rest } = args.adminData

      try {
        await store.dispatch('master-admin/updateAdmin', rest)
      } catch (err) {
        console.log(err)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Oops!',
            text: 'Something went wrong!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    [],
    { immediate: false },
  )

  return {
    allModulesName,
    statusOptions,
    modulesData,
    isLoading,
    // isModuleHasSomeTruthyValue,
    updateAdmin,
    defaultAbility,
    // uniqWith,
    hideAuth,
  }
}
