import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAdmins(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/account', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAdmin(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/account/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addAdmin(ctx, adminData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/useraccess/create', { ...adminData.data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateAdmin(ctx, adminData) {
      const { id, ...data } = adminData
      return new Promise((resolve, reject) => {
        axios
          .put(`/admin/account/${id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteAdmin(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/admin/useraccess/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
