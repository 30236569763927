import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchRoles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/role', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addRole(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/role/create', { ...payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editRole(ctx, { id, name }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/admin/role/update/${id}`, { role_name: name })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateRoleState(ctx, { id, isActive }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/admin/role/setstatus/${id}`, { is_active: isActive })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteRole(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/admin/role/delete/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
