import { ref, watch, computed } from '@vue/composition-api'

import { useToast } from 'vue-toastification/composition'
import { useAsyncState } from '@vueuse/core'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import listModule from '@/navigation/vertical'

import store from '@/store'

export default function useRoleList() {
  const toast = useToast()

  const refRoleListTable = ref(null)

  const tableColumns = [
    { key: 'name', sortable: true },
    { key: 'isDeleted', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalRole = ref(0)
  const roleOptions = ref([])
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('_id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)
  const modulesData = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refRoleListTable.value
      ? refRoleListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRole.value,
    }
  })

  const refetchData = () => {
    refRoleListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const defaultCrud = {
    create: false,
    read: false,
    update: false,
    delete: false,
  }

  const allModulesName = listModule.flatMap(module => {
    if (module.children) {
      return module.children.flatMap(item => item.resource)
    }

    if (module.title) {
      return module.resource
    }
    return []
  })

  const defaultAbility = listModule
    .flatMap(module => {
      if (module.children) {
        return module.children.flatMap(item => ({
          module: item.resource,
          ...defaultCrud,
        }))
      }

      if (module.title) {
        return { module: module.resource, ...defaultCrud }
      }
      return []
    })
    .concat({
      module: 'Auth',
      ...defaultCrud,
      read: true,
    })

  const uniqWith = (arr, fn) =>
    arr.filter((el, i) => arr.findIndex(step => fn(el, step)) === i)

  const hideAuth = (item, type) => {
    if (!item || type !== 'row') return
    // eslint-disable-next-line consistent-return
    if (item.module === 'Auth') return { hidden: true }
  }

  const isThereTrue = module => {
    if (module.module === 'Auth') return false
    if (module.create) return true
    if (module.read) return true
    if (module.update) return true
    if (module.delete) return true
    return false
  }

  const isModuleHasSomeTruthyValue = computed(() =>
    modulesData.value.some(field => isThereTrue(field)),
  )

  const ability = () =>
    modulesData.value.reduce((prev, curr) => {
      const { module, disabled, ...crud } = curr

      return isModuleHasSomeTruthyValue.value
        ? [
            ...prev,
            {
              subject: module,
              action: Object.keys(crud).filter(k => curr[k]),
            },
          ]
        : null
    }, [])

  // eslint-disable-next-line consistent-return
  const {
    state: roleState,
    isLoading: isRoleLoading,
    execute: fetchRoles,
  } = useAsyncState(
    async () => {
      try {
        const response = await store.dispatch('master-role/fetchRoles', {
          q: searchQuery.value,
          perPage: perPage.value,
          page: currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
          status: statusFilter.value,
        })

        if (response.data.code === 200) {
          const { roles, total } = response.data.data

          if (roles.length && roleOptions.value.length === 0) {
            roles.forEach(role => {
              const { id, name, isDeleted } = role

              roleOptions.value.push({
                label: name,
                value: id,
                isDeleted,
              })
            })
          }

          totalRole.value = total

          return roles
        }
      } catch (error) {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching Role' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
      return []
    },
    [],
    { immediate: false },
  )

  return {
    fetchRoles,
    isRoleLoading,
    roleOptions,
    roleState,
    tableColumns,
    perPage,
    currentPage,
    totalRole,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refRoleListTable,

    allModulesName,
    modulesData,
    isModuleHasSomeTruthyValue,
    defaultAbility,
    uniqWith,
    hideAuth,
    ability,

    statusFilter,

    refetchData,
  }
}
