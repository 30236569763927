<template>
  <component :is="adminData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="adminData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list' }"
        >
          Admin Member List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <b-tabs
      v-if="adminData"
      pills
    >
      <!-- Tab: Account -->
      <!-- <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('admin.account') }}</span>
        </template>
        <admin-edit-tab-account
          :user-data.sync="adminData"
          :role-options="roleOptions"
          class="mt-2 pt-75"
        />
      </b-tab> -->

      <admin-edit-tab-account
        :user-data.sync="adminData"
        :role-options="roleOptions"
      />

      <!-- Tab: Information -->
      <!-- <b-tab disabled>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('admin.info') }}</span>
        </template>
        <user-edit-tab-information class="mt-2 pt-75" />
      </b-tab> -->

      <!-- Tab: Social -->
      <!-- <b-tab disabled>
        <template #title>
          <feather-icon
            icon="Share2Icon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('admin.social') }}</span>
        </template>
        <user-edit-tab-social class="mt-2 pt-75" />
      </b-tab> -->
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import adminMemberStoreModule from '../adminMemberStoreModule'
import roleStoreModule from '../../role/roleStoreModule'
import AdminEditTabAccount from './AdminEditTabAccount.vue'
import useRoleList from '../../role/role-list/useRoleList'
// import UserEditTabInformation from './UserEditTabInformation.vue'
// import UserEditTabSocial from './UserEditTabSocial.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    AdminEditTabAccount,
    // UserEditTabInformation,
    // UserEditTabSocial,
  },

  setup() {
    const adminData = ref(null)
    const isAbilityChange = ref(false)
    const isAbilityEmpty = ref(true)
    const isAdminInactive = ref(false)
    const { fetchRoles, roleOptions } = useRoleList()

    const ADMIN_APP_STORE_MODULE_NAME = 'master-admin'
    const ROLE_APP_STORE_MODULE_NAME = 'master-role'

    // Register module
    if (
      !store.hasModule(
        ADMIN_APP_STORE_MODULE_NAME || ROLE_APP_STORE_MODULE_NAME,
      )
    ) {
      store.registerModule(ADMIN_APP_STORE_MODULE_NAME, adminMemberStoreModule)
      store.registerModule(ROLE_APP_STORE_MODULE_NAME, roleStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (
        store.hasModule(
          ADMIN_APP_STORE_MODULE_NAME || ROLE_APP_STORE_MODULE_NAME,
        )
      ) {
        store.unregisterModule(ADMIN_APP_STORE_MODULE_NAME)
        store.unregisterModule(ROLE_APP_STORE_MODULE_NAME)
      }
    })

    const fetchAdmin = store
      .dispatch('master-admin/fetchAdmin', {
        id: router.currentRoute.params.id,
      })
      .then(response => {
        isAdminInactive.value = !response.data.data.isActive

        return response.data.data
      })

    Promise.all([fetchAdmin, fetchRoles()]).then(response => {
      // eslint-disable-next-line prefer-destructuring
      adminData.value = response[0]
      const { value, isDeleted } = roleOptions.value.find(
        role => role.label === adminData.value.role.name,
      )
      adminData.value.role = value
      adminData.value.isRoleDeleted = isDeleted
    })

    return {
      adminData,
      roleOptions,
      isAbilityChange,
      isAbilityEmpty,
      isAdminInactive,
    }
  },
}
</script>
