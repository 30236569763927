<template>
  <div>
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="userData.imgProfile"
          :text="avatarText(userData.name)"
          variant="light-warning"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ userData.name }}
      </h4>
      <div class="d-flex flex-wrap">
        <b-button variant="primary" @click="$refs.refInputEl.click()">
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          />
          <span class="d-none d-sm-inline">{{ $t('common.update') }}</span>
          <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
        </b-button>
        <b-button variant="outline-secondary" class="ml-1">
          <span class="d-none d-sm-inline">{{ $t('common.remove') }}</span>
          <feather-icon icon="TrashIcon" class="d-inline d-sm-none" />
        </b-button>
      </div>
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
        <!-- Field: Full Name -->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('admin.form.name')" label-for="full-name">
            <b-form-input id="full-name" v-model="userData.name" />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('admin.form.email')" label-for="email">
            <b-form-input id="email" v-model="userData.email" type="email" />
          </b-form-group>
        </b-col>

        <!-- Field: Status -->
        <b-col cols="12" md="4">
          <b-form-group
            :label="$t('admin.form.status')"
            label-for="user-status"
          >
            <v-select
              v-model="userData.isActive"
              input-id="user-status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :reduce="val => val.value"
              :clearable="false"
              :disabled="userData.isRoleDeleted"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Role -->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('admin.form.role')" label-for="user-role">
            <v-select
              v-model="userData.role"
              class="text-capitalize"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="roleOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="user-role"
              :selectable="opt => !opt.isDeleted"
            >
              <template #option="{ label, isDeleted }">
                <div class="d-flex">
                  <span class="mr-1">{{ label }}</span>
                  <b-badge v-if="isDeleted" variant="danger">
                    {{ $t('common.deleted') }}
                  </b-badge>
                </div>
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
      </b-row>
    </b-form>

    <!-- PERMISSION TABLE -->

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      :disabled="isLoading"
      @click="onSubmit"
    >
      <template v-if="isLoading">
        <div class="d-flex align-center justify-content-center">
          <b-spinner type="grow" small class="mr-1" />
          Loading...
        </div>
      </template>
      <span v-else>{{ formHasChanged ? $t('admin.saveBtn') : 'Kembali' }}</span>
    </b-button>
    <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      {{ $t('common.reset') }}
    </b-button>
    <!-- <pre>{{ moduleData }}</pre> -->
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BSpinner,
  BBadge,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, computed } from '@vue/composition-api'
// import useAdminMembersList from '../admin-member-list/useAdminMembersList'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useAdminMemberEdit from './useAdminMemberEdit'
import router from '@/router'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BSpinner,
    vSelect,
    BBadge,
  },
  model: {
    prop: ['isAbilityChange', 'isAbilityEmpty'],
    event: ['update:is-ability-change', 'update:is-ability-empty'],
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
  },

  setup(props) {
    // const { resolveUserRoleVariant } = useAdminMembersList()
    const {
      isLoading,
      updateAdmin,
      // modulesData,
      // defaultAbility,
      // uniqWith,
      // allModulesName,
      // hideAuth,
      statusOptions,
      // isModuleHasSomeTruthyValue,
    } = useAdminMemberEdit()
    const toast = useToast()

    const defaultForm = {
      name: props.userData.name,
      email: props.userData.email,
      isActive: props.userData.isActive,
      username: props.userData.username,
      role: props.userData.role.name,
    }

    const formHasChanged = computed(() =>
      Object.keys(defaultForm).some(
        key => defaultForm[key] !== props.userData[key],
      ),
    )

    // watch(
    //   () => formHasChanged.value,
    //   val => {
    //     emit('update:is-ability-change', val)
    //   },
    // )

    // const abilityFromUserDataFetch = props.userData.ability.map(e => {
    //   const defObj = {
    //     create: false,
    //     read: false,
    //     update: false,
    //     delete: false,
    //   }
    //   const obj = e.action.reduce((acc, cur) => {
    //     acc[cur] = true
    //     return acc
    //   }, {})

    //   if (e.subject === 'Auth') {
    //     defObj.disabled = true
    //   }

    //   return {
    //     module: e.subject,
    //     ...defObj,
    //     ...obj,
    //   }
    // })

    // if (props.isAbilityEmpty) {
    //   modulesData.value = defaultAbility
    // } else {
    //   const data = [...abilityFromUserDataFetch, ...defaultAbility]
    //   modulesData.value = uniqWith(
    //     data,
    //     (a, b) => a.module === b.module,
    //   ).filter(e => allModulesName.includes(e.module))
    // }

    // const toggleValue = (idx, key) => {
    //   modulesData.value[idx][key] = !modulesData.value[idx][key]
    //   emit('update:is-ability-change', true)
    //   emit('update:is-ability-empty', !isModuleHasSomeTruthyValue.value)
    // }

    const onSubmit = async () => {
      await updateAdmin(0, { adminData: props.userData }).then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Got it!',
            text: `${props.userData.name} data successfully updated!`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      })

      router.push({ name: 'master-admin-member' })
    }

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.imgProfile = base64
      previewEl.value.refresh()
    })

    // onMounted(() => {
    //   emit('update:is-ability-empty', !isModuleHasSomeTruthyValue.value)
    // })

    return {
      // resolveUserRoleVariant,
      avatarText,
      statusOptions,
      // modulesData,
      // toggleValue,
      onSubmit,
      // hideAuth,
      isLoading,
      formHasChanged,
      // isModuleHasSomeTruthyValue,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
